import { useState } from "react";
import classes from "../css/Formula.module.css";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "pretty-checkbox-react";
import { getAge } from "../../utils/weekFormatter";

//{`${classes.input} ${errorMsg ? classes.shake : ""}`}

const initState = {
  firstName: "",
  lastName: "",
  birthdate: "",
  city: "",
  zip: "",
  email: "",
  phone: "",
  schooltype: "",
  schoolyear: "",
};

const initAdress = {
  street: "",
  streetnumber: "",
};

export function StudentForm() {
  const [student, setStudent] = useState(initState);
  const [address, setAddress] = useState(initAdress);
  const [errorMsg, setErrorMsg] = useState("");
  const [termsAccepted, setAccepted] = useState(false);
  const navigate = useNavigate();
  const fetch_url = process.env.REACT_APP_FETCHING_SERVER;

  function changeStudentValue(i, value) {
    const newStudent = structuredClone(student);
    newStudent[i] = value;
    if (newStudent[i].toString().length > 100) {
      newStudent[i] = "";
    }
    setStudent(newStudent);
  }

  function changeAddressValue(i, value) {
    const newAddress = structuredClone(address);
    newAddress[i] = value;
    if (newAddress[i].toString().length > 100) {
      newAddress[i] = "";
    }
    setAddress(newAddress);
  }

  function checkSubmit() {
    if (Object.values(student).includes("")) {
      setErrorMsg("Es müssen alle Felder korrekt ausgefüllt werden!");
      return;
    }

    if (!student["email"].includes("@")) {
      setErrorMsg("Gebe eine valide Email-Adresse ein!");
      return;
    }

    if (!termsAccepted) {
      setErrorMsg("Du musst einverstanden mit der Datenschutzerklärung sein!");
      return;
    }

    submit();
  }

  const sendEmail = () => {
    console.log(student["email"]);
    fetch(fetch_url + "/verify", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: student["email"] }),
    }).catch((error) => {
      console.error("Error sending verification email: ", error);
    });
  };

  function submit() {
    const studentObject = structuredClone(student);
    studentObject.address = `${address.street} ${address.streetnumber}`;
    studentObject.age = getAge(studentObject.birthdate);
    fetch(fetch_url + "/submit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(studentObject),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Successfully fetched student data: ", data);

        if (data.message) {
          setErrorMsg(data.message);
        } else {
          sendEmail();
          setStudent(initState);
          navigate("/formula/success", { state: { fromSubmission: true } });
        }
      })
      .catch((error) => {
        console.error("Failed fetching student data: ", error);
        setStudent(initState);
        navigate("/formula/fail", { state: { fromSubmission: true } });
      });
  }

  return (
    <div className={classes["form-container"]}>
      <h2 className={classes.h2}>Antragsformular</h2>
      <div className={classes.form}>
        <div className={`${classes["form-group"]} ${classes.surname}`}>
          <label htmlFor="surname" className={classes.req}>
            Name
          </label>
          <input
            type="text"
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            value={student["lastName"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeStudentValue("lastName", event.target.value)
            }
            id="surname"
            required
          />
        </div>
        <div className={`${classes["form-group"]} ${classes.name}`}>
          <label htmlFor="name" className={classes.req}>
            Vorname
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="text"
            id="name"
            value={student["firstName"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeStudentValue("firstName", event.target.value)
            }
            required
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.birthdate}`}>
          <label htmlFor="datepicker" className={classes.req}>
            Geburtstag
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="date"
            id="datepicker"
            value={student["birthdate"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeStudentValue("birthdate", event.target.value)
            }
            required
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.street}`}>
          <label htmlFor="street" className={classes.req}>
            Straße
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="text"
            id="street"
            value={address["street"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeAddressValue("street", event.target.value)
            }
            required
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.streetnumber}`}>
          <label htmlFor="streetnumber" className={classes.req}>
            Hausnr.
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="text"
            id="streetnumber"
            value={address["streetnumber"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeAddressValue("streetnumber", event.target.value)
            }
            required
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.plz}`}>
          <label htmlFor="plz" className={classes.req}>
            PLZ
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="number"
            id="plz"
            value={student["zip"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) => changeStudentValue("zip", event.target.value)}
            required
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.town}`}>
          <label htmlFor="town" className={classes.req}>
            Ort
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="text"
            id="town"
            value={student["city"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) => changeStudentValue("city", event.target.value)}
            required
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.email}`}>
          <label htmlFor="email" className={classes.req}>
            E-Mail
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="email"
            id="email"
            value={student["email"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeStudentValue("email", event.target.value)
            }
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.telephone}`}>
          <label htmlFor="telephone" className={classes.req}>
            Tel-Nr.
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="tel"
            id="telephone"
            value={student["phone"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeStudentValue("phone", event.target.value)
            }
            required
          />
        </div>

        <div className={`${classes["form-group"]} ${classes.selection}`}>
          <label htmlFor="select" className={classes.req}>
            Schulart
          </label>
          <select
            className={`${classes.select} ${errorMsg ? classes.shake : ""}`}
            id="select"
            defaultValue={student["schooltype"]}
            value={student["schooltype"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeStudentValue("schooltype", event.target.value)
            }
            required
          >
            <option value="" disabled>
              -- Bitte wählen --
            </option>
            <option value="Gymnasium">Gymnasium</option>
            <option value="Realschule">Realschule</option>
            <option value="Mittelschule">Mittelschule</option>
            <option value="FOS/BOS">FOS/BOS</option>
            <option value="Wirtschaftsschule">Wirtschaftsschule</option>
            <option value="Andere">Andere</option>
          </select>
        </div>

        <div className={`${classes["form-group"]} ${classes.schoolyear}`}>
          <label htmlFor="schoolyear" className={classes.req}>
            Stufe
          </label>
          <input
            className={`${classes.input} ${errorMsg ? classes.shake : ""}`}
            type="number"
            min="1"
            max="13"
            value={student["schoolyear"]}
            onClick={() => setErrorMsg("")}
            onChange={(event) =>
              changeStudentValue("schoolyear", event.target.value)
            }
            required
          />
        </div>
        <div className={`${classes["form-group"]} ${classes["error-message"]}`}>
          <p>{errorMsg}</p>
        </div>
        <div
          className={`${classes["form-group"]} ${classes["checkbox-accept"]}`}
        >
          <Checkbox
            className={classes.checkbox}
            required
            checked={termsAccepted}
            onChange={() => setAccepted(!termsAccepted)}
          >
            Ich akzeptiere die{" "}
          </Checkbox>
          <a href="/privacy-policy">Datenschutzerklärung</a>
        </div>
        <div className={`${classes["form-group"]} ${classes.submit}`}>
          <button type="button" id="submit" onClick={checkSubmit}>
            Absenden
          </button>
        </div>
      </div>
    </div>
  );
}
