import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../css/Main.module.css";
import { useSessionStorage } from "primereact/hooks";

export function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const fetch_url = process.env.REACT_APP_FETCHING_SERVER;
  const [token, setToken] = useSessionStorage("", "token");

  function authenticate() {
    fetch(fetch_url + "/authenticate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["isAuthenticated"] == true) {
          navigate("/billing", { state: { isAuthenticated: true } });
          setToken(result["x-api-key"]);
        } else {
          setErrorMsg("Username or Password incorrect!");
        }
      })
      .catch((error) => {
        console.error("Failed authenticating user: ", error);
      });
  }

  return (
    <div className={classes.body}>
      <div className={`${classes.containers} ${classes["login-container"]}`}>
        <input
          className={classes.input}
          placeholder={"Username"}
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className={classes.input}
          placeholder={"Password"}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p color={"red"}>{errorMsg}</p>
        <button className={classes.button} onClick={authenticate}>
          Bestätigen
        </button>
      </div>
    </div>
  );
}
