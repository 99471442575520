import { useEffect, useState } from "react";
import { Trow } from "./table-element";
import { Thead } from "./table-head";
import classes from "../css/Main.module.css";
import table from "../css/Table.module.css";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

export function Table({ selectedID = "" }) {
  const [appointments, setAppointments] = useState(null);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(0);

  function removeRow(id) {
    setAppointments(
      appointments.filter((appointment) => appointment.AppointmentID !== id)
    );
  }

  function submit() {
    if (selectedID === "") {
      alert("Wähle einen Schüler aus!");
      return;
    }

    if (appointments.length === 0) {
      alert("Wähle einen Schüler mit Terminen aus!");
      return;
    }

    const sendData = async () => {
      fetch(json_url + "/submit-billing", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          student: student,
          appointments: appointments,
        }),
      })
        .then((response) => {
          const filename = response.headers
            .get("Content-Disposition")
            .split("filename=")[1];

          return validateResponse(response)
            .blob()
            .then((blob) => ({ blob: blob, filename: filename }));
        })
        .then((data) => {
          const url = URL.createObjectURL(data.blob);
          console.log(data.filename, url);
          const a = document.createElement("a");
          a.download = `${data.filename}.pdf`;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          a.remove();
          console.log("Successfully sent data and received response!");
          setLoadingCount(loadingCount + 1);
        })
        .catch((error) => {
          console.error("Error sending data to server:", error);
        });
    };
    sendData();
  }

  useEffect(() => {
    if (selectedID === "") {
      setLoading(false);
      setAppointments([]);
      return;
    }

    const fetchData = async () => {
      try {
        //fetch student and appointments data
        const appointmentsResponse = await fetch(
          json_url + `/data/appointments?id=${selectedID}`,
          { headers: { "x-api-key": sessionStorage.getItem("token") } }
        );
        const studentResponse = await fetch(json_url + "/data/students", {
          headers: { "x-api-key": sessionStorage.getItem("token") },
        });

        //convert data to json
        const studentResult = await validateResponse(studentResponse).json();
        const appointmentsResult = await validateResponse(
          appointmentsResponse
        ).json();

        //set new states with json data
        setAppointments(appointmentsResult);
        setStudent(
          studentResult.filter((student) => student.studentID == selectedID)[0]
        );
        //console.log(appointmentsResult);
      } catch (error) {
        console.error("Error whilst fetching data: " + error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedID, loadingCount]);

  function convertToTrow(arr) {
    if (loading) return <tr>Daten werden geladen...</tr>;
    if (!arr) return <tr>Keine Daten verfügbar!</tr>;

    return arr.map((appointment, index) => {
      const buttons = { Löschen: () => removeRow(appointment.AppointmentID) };
      return (
        <Trow
          rowCount={index + 1}
          date={appointment.DateTime}
          service={appointment.ServiceName}
          quantity={parseFloat(appointment.Quantity).toFixed(2)}
          price={parseFloat(appointment.Price).toFixed(2)}
          totalprice={parseFloat(appointment.TotalPrice).toFixed(2)}
          buttons={buttons}
        />
      );
    });
  }

  return (
    <>
      <table className={table.table} id="invoiceTable">
        <Thead
          titles={[
            "Pos.",
            "Datum",
            "Leistung",
            "Menge",
            "Einzelpreis",
            "Gesamtpreis",
            "Aktion",
          ]}
        />
        <tbody>{convertToTrow(appointments)}</tbody>
      </table>
      <div className={classes["button-menu"]}>
        <button className={classes.button} onClick={submit}>
          Absenden
        </button>
        <button
          className={classes.button}
          onClick={() => setLoadingCount(loadingCount + 1)}
        >
          Aktualisieren
        </button>
      </div>
    </>
  );
}
